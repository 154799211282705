<template>
    <transition>
		<div>
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px pb-6">
					<div class="flex justify-start items-center gap-x-3">
						<div>
							추출기간
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								:width="120"
								v-model="searchType.customTypes.frRecDate"
								type="date"
								dateSerializationFormat="yyyyMMdd"
								display-format="yyyy.MM.dd"
							>
							</DxDateBox>
						</div>
						<div class="ui-datepicker period">
							<span class="dash">~</span>
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								:width="120"
								v-model="searchType.customTypes.toRecDate"
								type="date"
								dateSerializationFormat="yyyyMMdd"
								display-format="yyyy.MM.dd"
							>
							</DxDateBox>
						</div>

						<div>
							추출통화시간
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								:width="100"
								v-model="searchType.customTypes.frRecTime"
								type="time"
								dateSerializationFormat="yyyyMMddTHHmmss"
								display-format="HH:mm"
							>
							</DxDateBox>
						</div>
						<div class="ui-datepicker period">
							<span class="dash">~</span>
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								:width="100"
								v-model="searchType.customTypes.toRecTime"
								type="time"
								dateSerializationFormat="yyyyMMddTHHmmss"
								display-format="HH:mm"
							>
							</DxDateBox>
						</div>

						<DxButton
							text="검색"
							class="btn_M box-btn-search"
							type="button"
							:height="30"
							@click="e => {
									if (this.$_commonlib.isEmpty(searchType.customTypes.frRecDate) ||
										this.$_commonlib.isEmpty(searchType.customTypes.toRecDate)) {
										this.$_Msg('추출기간을 선택하세요');
										return;
									}
									if (this.$_commonlib.isEmpty(searchType.customTypes.frRecTime) ||
										this.$_commonlib.isEmpty(searchType.customTypes.toRecTime)) {
										this.$_Msg('추출시간을 선택하세요');
										return;
									}
									selectDataList();
								}"
						/>
					</div>
				</div>
			</div>

			<div class="flex justify-end gap-x-4 mb-4">
				<div>추출가능 건수 : {{ evalScheRoundData.recCount }}</div>
				<div>저장 건수 : {{ savedRecordIds.length }}</div>
				<div>선택 건수 : {{ selectedRowKeys.length }}</div>
				<!-- <div>{{ evalScheRoundData.recDrawTypeCd }}</div> -->
			</div>

			<DxDataGrid
				class="grid-box"
				key-expr="id"
				:data-source="gridDataSource"
				:show-borders="false"
				:show-column-headers="true"
				:show-column-lines="true"
				:show-row-lines="true"
				:row-alternation-enabled="false"
				:hover-state-enabled="true"
				:word-wrap-enabled="true"
				:no-data-text="this.$_msgContents('CMN_NO_DATA')"
				:selected-row-keys="selectedRowKeys"
				width="100%"
				height="350"
				@selection-changed="onSelectionChanged"
			>
				<DxLoadPanel :enabled="true" />
				<DxScrolling mode="virtual" />
				<DxSelection
					mode="multiple"
					:allow-select-all="false"
					show-check-boxes-mode="always"
					select-all-mode="allPages"
				/>

				<DxColumn
					caption="콜구분"
					data-field="recTypeCd"
					:allowEditing="false"
					:allow-sorting="false"
					alignment="center"
					:visible="true"
					width="110"
					height="40"
				>
					<DxLookup
						:data-source="dataGridLoopup.recTypeCd.dataSource"
						value-expr="codeId"
						display-expr="codeNm"
					/>
				</DxColumn>
				<DxColumn
					caption="CALLID"
					data-field="callId"
					alignment="center"
					:allowEditing="false"
					:allow-sorting="true"
				/>
				<DxColumn
					caption="통화일"
					data-field="recDate"
					alignment="center"
					:allowEditing="false"
					:allow-sorting="true"
				/>
				<DxColumn
					caption="통화시작시간"
					alignment="center"
					data-field="recStartTime"
					:allowEditing="false"
					:allow-sorting="false"
					:calculate-display-value="rowData => rowData.recStartTime ? this.$_commonlib.formatDate(rowData.recStartTime, 'HHmmSS', 'HH:mm:SS') : null"
				/>
				<DxColumn
					caption="통화종료시간"
					alignment="center"
					data-field="recEndTime"
					:allowEditing="false"
					:allow-sorting="false"
					:calculate-display-value="rowData => rowData.recEndTime ? this.$_commonlib.formatDate(rowData.recEndTime, 'HHmmSS', 'HH:mm:SS') : null"
				/>
				<DxColumn
					caption="통화시간"
					data-field="recElapsedTime"
					alignment="center"
					:allowEditing="false"
					:allow-sorting="true"
					:calculate-display-value="calDisplayValRecElapsedTime"
				/>
				<DxColumn
					caption="청취"
					data-field="id"
					cell-template="playTemplate"
					:allowEditing="false"
					:allow-sorting="false"
					alignment="center"
					:visible="true"
					:width="55"
				/>
				<template #playTemplate="{ data }">
					<DxButton
						text=""
						:element-attr="{ class: 'btn_XS white outlined mr-0' }"
						height="30"
						hint="청취"
						template="<span class='mdi mdi-volume-high'></span>"
						@click="onRoundRecordPlay($event, data.data)"
					/>
				</template>
			</DxDataGrid>
		</div>
    </transition>
</template>

<script>
import { isSuccess } from '@/plugins/common-lib';
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import {
    DxDataGrid,
    DxColumn,
	DxLoadPanel,
	DxLookup,
    DxScrolling,
	DxSelection,
} from 'devextreme-vue/data-grid';
import moment from "moment/moment";

let vm;

export default {
    components: {
		DxButton,
		DxDateBox,
		DxDataGrid,
        DxColumn,
        DxLoadPanel,
        DxLookup,
        DxScrolling,
        DxSelection,
	},
	props: {
		modalData: Object,
	},
	watch: {
		/* formData: {
			//data with key and list to send to parent component
			handler(val) {
				/* let filterData = val;
				if (filterData) {
					this.$emit('input', filterData);
				} /
			},
			deep: true,
		}, */
	},
	data() {
		return {
            codeMap: {},
            gridData: {
                columnDataSource: {
                    useFl: [
                        { cd: 'Y', nm: '사용' },
                        { cd: 'N', nm: '미사용' },
                    ],
                }
            },
			dataGridLoopup: {
				recTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId'
				}
			},
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
            searchType: {
				customTypes: {
					frRecDate: null,
					toRecDate: null,
					frRecTime: null,
					toRecTime: null,
				},
                paramsData: {
					scheId: null,
					roundId: null,
					frRecDate: null,
					toRecDate: null,
					frRecTime: null,
					toRecTime: null,
					agtId: null,
					recMinTime: null,
					recMaxTime: null,
				},
            },
			evalScheRoundData : {
				id: null,
				evalRound: null,
				recCount: null,
				recStartDt: null,
				recEndDt: null,
				recStartTime: null,
				recEndTime: null,
				recMinTime: null,
				recMaxTime: null,
				recDrawTypeCd: null,
			},
            gridDataSource: null,
			selectedRowKeys: [],
			selectedRowData: [],
			savedRecordIds: [],
			remainRecCount: 0,
		};
	},
	computed: {},
	methods: {
		/** @description : 회차정보 조회 */
		async selectRoundData() {
			const payload = {
				actionname: 'EWM_EVALUATION_STATUS_SCHE_ROUND',
				data: { id: this.searchType.paramsData.roundId },
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				if(res.data.data.length > 0) {
					this.evalScheRoundData = res.data.data[0];

					const today = new Date(moment().format('YYYY-MM-DD'));

					const startHours = parseInt(this.evalScheRoundData.recStartTime.substring(0, 2));
					const startMinutes = parseInt(this.evalScheRoundData.recStartTime.substring(2, 4));
					today.setHours(startHours);
					today.setMinutes(startMinutes);

					const recStartDateTime = this.$_commonlib.moment(today).format('YYYY-MM-DD HH:mm:SS');

					const endHours = parseInt(this.evalScheRoundData.recEndTime.substring(0, 2));
					const endMinutes = parseInt(this.evalScheRoundData.recEndTime.substring(2, 4));
					today.setHours(endHours);
					today.setMinutes(endMinutes);
					const recEndDateTime = this.$_commonlib.moment(today).format('YYYY-MM-DD HH:mm:SS');

					this.searchType.customTypes.frRecDate = this.$_commonlib.formatDate(this.evalScheRoundData.recStartDt, 'YYYY-MM-DD', 'YYYYMMDD');
					this.searchType.customTypes.toRecDate = this.$_commonlib.formatDate(this.evalScheRoundData.recEndDt, 'YYYY-MM-DD', 'YYYYMMDD');
					this.searchType.customTypes.frRecTime = recStartDateTime;
					this.searchType.customTypes.toRecTime = recEndDateTime;
					this.searchType.paramsData.recMinTime = this.evalScheRoundData.recMinTime;
					this.searchType.paramsData.recMaxTime = this.evalScheRoundData.recMaxTime;

					this.remainRecCount = this.evalScheRoundData.recCount - this.savedRecordIds.length;

					this.selectDataList();
				}
			}
		},
		/** @description : 녹취콜 리스트 조회 */
        async selectDataList() {

			vm.searchType.paramsData['frRecDate'] = vm.searchType.customTypes.frRecDate.replace('-', '');
			vm.searchType.paramsData['toRecDate'] = vm.searchType.customTypes.toRecDate.replace('-', '');
			vm.searchType.paramsData['frRecTime'] = this.$_commonlib.moment(vm.searchType.customTypes.frRecTime).format('HHmmSS');
			vm.searchType.paramsData['toRecTime'] = this.$_commonlib.moment(vm.searchType.customTypes.toRecTime).format('HHmmSS');

			const payload = {
				actionname: 'EWM_EVALUATION_STATUS_RECORD_LIST',
				data: this.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.gridDataSource = res.data.data.filter(z => !this.savedRecordIds.includes(z.id));
				//this.selectedRowKeys = this.$_commonlib.cloneObj(this.savedRecordIds);

				if(this.evalScheRoundData.recDrawTypeCd == 1134) { //추후 추출방식 코드로 수정해야함

					if(this.gridDataSource.length <= this.remainRecCount) {
						this.selectedRowKeys = this.gridDataSource.map(z => z.id);

					} else {
						const randomIds = [];

						while (randomIds.length < this.remainRecCount) {
							const randomNum = Math.floor(Math.random() * this.gridDataSource.length);

							if (!randomIds.includes(this.gridDataSource[randomNum].id)) {
								randomIds.push(randomNum);
							}
						}

						this.selectedRowKeys = randomIds;
					}
				}
			}
		},
		/** @description : 통화시간 계산 */
		calDisplayValRecElapsedTime(rowData) {
			const startTime = `${rowData.recDate}T${this.$_commonlib.formatDate(rowData.recStartTime, 'HHmmSS', 'HH:mm:SS')}`;
			const endTime = `${rowData.recDate}T${this.$_commonlib.formatDate(rowData.recEndTime, 'HHmmSS', 'HH:mm:SS')}`;

			return this.$_commonlib.calculateElapsedTime(startTime, endTime, ':');
		},
		/** @description : 녹취콜 청취 */
		onRoundRecordPlay(data) {
			this.$_Msg('준비중');
		},
		onSelectionChanged(e) {
			this.selectedRowKeys = e.selectedRowKeys;

			if(e.selectedRowKeys.length > vm.remainRecCount) {
				this.$_Msg(`저장 가능한 데이터는 ${vm.evalScheRoundData.recCount}개 입니다. 해당 개수를 초과 합니다.`);
				this.selectedRowKeys = e.selectedRowKeys.filter(key => key != e.currentSelectedRowKeys[0]);
			}

			this.selectedRowData = e.selectedRowsData;
		},
        /** @description : 코드 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_eval_process');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
            vm = this;

			this.searchType.paramsData.scheId = this.modalData.scheId;
			this.searchType.paramsData.roundId = this.modalData.roundId;
			this.searchType.paramsData.agtId = this.modalData.agtId;
			this.savedRecordIds = this.modalData.savedRecordIds;

            this.initCodeMap()
			.then(() => {
				this.dataGridLoopup.recTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_call_type'], 2);
			});

			this.selectRoundData();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			this.$_eventbus.$on('ModalEvalRecordList:onSaveData', async (e, resolve) => {

				if(this.selectedRowKeys.length < 1) {
					this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
					return false;
				}

				if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' })))) {
					return false;
				}

				if(vm.remainRecCount < 1) {
					this.$_Msg(`저장 가능한 데이터는 ${vm.evalScheRoundData.recCount}개 입니다. 해당 개수를 초과 합니다.`);
					return false;
				}

				const saveData = this.selectedRowData.map(z => {
					return {
						scheId: this.searchType.paramsData.scheId,
						roundId: this.searchType.paramsData.roundId,
						recordId: z.id,
						callId: z.callId,
					}
				});

				let payload = {
					actionname: 'EWM_EVALUATION_STATUS_ROUND_RECORD_SAVE',
					data: {data: saveData},
					loading: true,
				};
				let res = await this.CALL_EWM_API(payload);
				if (res.status === 200) {
					if (res.data.header.resCode === 'success') {
						this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
						resolve(res);
					} else {
						this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
					}
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			});			
		},
		/** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
		beforeDestroyedData() {
			this.$_eventbus.$off('ModalEvalRecordList:onSaveData');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	beforeDestroy() {
		this.beforeDestroyedData();
	},
	destroyed() {}
};
</script>